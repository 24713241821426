import { useState, useRef, useEffect } from 'react';
import { ArrowDown, ListIcon, ChainIcon } from 'modules/v2/common/components/SvgIcon';
import { DigitalPrintOptions } from 'modules/v2/utils/guideUtils';

const Dropdown = ({ items, setSelectedQtyOption, setGuideQuantity, selectedQtyOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const setOnClick = (value) => {
    const result = items.find(item => item.id === value.id);
    setSelectedQtyOption(result);
    setGuideQuantity(result?.qty);
    setIsOpen(false);
  };

  const setOnClickDigital = () => {
    setSelectedQtyOption(DigitalPrintOptions);
    setGuideQuantity(DigitalPrintOptions?.qty);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="relative" ref={ref}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        id="dropdownDefaultButton"
        className="border w-[188px] border-neutral-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-md text-sm px-4 py-3 text-center inline-flex items-center"
        type="button"
      >
        <div className="flex justify-between w-full">
          <div>{selectedQtyOption?.label}</div>
          <div className="justify-end content-center">
            <ArrowDown />
          </div>
        </div>
      </button>

      {isOpen && (
        <div 
          id="dropdown" 
          className="absolute top-full mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-[263px] ml-[-72px] z-50"
        >
          <ul className="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
            {items.map((item) => (
              <li
                key={item.id}
                className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => setOnClick(item)}
              >
                <div className="flex">
                  <div className="w-[10%]"><ListIcon /></div>
                  <div className="pl-1 w-[70%] text-sm font-medium">{item.label}</div>
                  <div className="pl-4 justify-items-end text-sm font-medium text-neutral-400">${item.price}</div>
                </div>
              </li>
            ))}
            <li className="px-4 py-2">
              <hr className="w-full" />
            </li>
            <li
              className="block px-4 py-2 hover:bg-gray-100 cursor-pointer" 
              onClick={setOnClickDigital}
            >
              <div className="flex">
                <div className="w-[10%]"><ChainIcon /></div>
                <div className="pl-1 w-[70%] text-sm font-medium">Digital Only</div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
import { DashboardTemplate } from 'modules/v2/templates';

import { GuidePreview } from './components/GuidePreview';
import mockData from './mocks/index';

const GuideCatalog = () => {
  return (
    <DashboardTemplate title="Guide Catalog">
      <div className="bg-neutral-0 py-[15px] px-6 rounded-b-lg shadow-box">
        <div className="grid gap-5 grid-cols-[repeat(auto-fill,252px)] w-full justify-center py-[30px] px-6">
          {Array.from({ length: 4 }).map(() => (
            <GuidePreview guideDetails={mockData[0]} />
          ))}
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default GuideCatalog;


import { useHistory } from 'react-router-dom';
import { Box } from 'modules/v2/common/AtomicDesign/atoms';
import { getMyCheckout } from 'modules/v2/routes/navigation';
import { GuideCheckoutStatus } from 'modules/v2/utils/guideUtils';

import OrderedCard from '../OrderedCard';

const OrderedGuides = () => {
  const history = useHistory();
  const mockFetchOrderListResponse = [
    {
      guideId: 1234,
      title: 'A guide to selling your home',
      thumbnail: {
        url: 'https://afy-ui-stg.s3.us-east-1.amazonaws.com/images/seller/seller-guide-blue.png',
      },
      html: '<p>Some HTML content</p>',
      orderId: '0000111',
      qty: 8,
      shippingAddress: {
        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2',
        city: 'city',
        state: 'Florida',
        country: 'US',
        pincode: '32233',
      },
      createdAt: 'Nov 4, 2024, 3:26 PM',
    },
  ];

  const navigateToCheckout = (orderId) => {
    history.push(getMyCheckout(GuideCheckoutStatus.ORDERED, orderId));
  };

  if (mockFetchOrderListResponse?.length > 0) {
    return mockFetchOrderListResponse.map((d) => (
      <OrderedCard
        key={d.guideId}
        orderData={d}
        onBuyAgain={navigateToCheckout}
        orderType="guide"
      />
    ));
  }

  return (
    <Box className="mb-6 p-7 justify-center items-center font-figtree relative text-neutral-600 text-center">
      No orders available
    </Box>
  );
};

export default OrderedGuides;

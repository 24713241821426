import { useEffect, useState } from 'react';
import { Tooltip } from 'flowbite-react';
import env from 'environments';
import { cn } from 'modules/v2/common/utils';
import { useCustomerPlanInfo } from 'modules/v2/utils';
import { hasOnlyRMAccess, checkRMSubscriptionAccess } from 'modules/v2/utils/hasOnlyRMAccess';
import { useBookAccess } from 'modules/v2/utils/useBookAccess';
import { hideBookAccess } from 'modules/v2/utils/hideBookAccess';
import { isPilotCustomer } from 'modules/v2/utils/isPilotCustomer';
import { getData } from 'modules/auth';
import { useSelector, useDispatch } from 'react-redux';
import { updateIsBooks, updateIsRm, updateIsGuides } from 'shared/store/actions/bundleAccess';
import DropdownMenu from './DropdownMenu';
import navigationRoutes from './navigationRoutes';
import MenuItem from './MenuItem';
import {
  CAMPAIGNS,
  LEADS,
  MY_BOOKS,
  REFERRAL_MARKETING,
  TRAINING,
  WELCOME_JOURNEY,
  GUIDES_AUTOMATION,
  CAMPAIGNS_KEY,
  LEADS_KEY,
  MY_BOOKS_GUIDES,
  REFERRAL_MARKETING_GUIDES,
} from './constants';

const SidebarMenu = ({ hasTrial = false }) => {
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [welcomeJourneyEligible, setWelcomeJourneyEligible] = useState(true);
  const { hasBookAccess, isLoading } = useBookAccess();
  const { isRm, isBooks, isGuides } = useSelector((data: any) => data.bundleAccess);
  const showGuidesTab = env.VITE_GUIDES_AUTOMATION;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(updateIsGuides(showGuidesTab));
    const { userData } = getData();
    if (userData?.welcomeJourneyEligible) {
      setWelcomeJourneyEligible(true);
    } else {
      setWelcomeJourneyEligible(false);
    }
  }, []);

  useEffect(() => {
    dispatch(updateIsBooks(hasBookAccess && !hideBookAccess()));
    dispatch(updateIsRm(isPilotCustomer() || hasOnlyRMAccess() || checkRMSubscriptionAccess()));
  }, [hasBookAccess]);

  function shouldDisplayMenuItem(item: { key: string }) {
    if (item?.key === GUIDES_AUTOMATION) {
      return showGuidesTab;
    }
    return (
      (welcomeJourneyEligible !== false && item?.key === WELCOME_JOURNEY) ||
      (item.key === CAMPAIGNS_KEY && (isRm || isBooks)) ||
      (item.key === LEADS_KEY && (isRm || isBooks)) ||
      (item.key === MY_BOOKS_GUIDES && !isBooks) ||
      (item.key === REFERRAL_MARKETING_GUIDES && !isRm) ||
      (isRm && item?.key === REFERRAL_MARKETING) ||
      (isBooks && item?.key === MY_BOOKS) ||
      ![
        MY_BOOKS,
        REFERRAL_MARKETING,
        WELCOME_JOURNEY,
        CAMPAIGNS_KEY,
        LEADS_KEY,
        MY_BOOKS_GUIDES,
        REFERRAL_MARKETING_GUIDES,
      ].includes(item?.key)
    );
  }

  const isMobile = windowSize < 1024;

  const isDigitalCustomer = useCustomerPlanInfo();
  const indexMyBooks = navigationRoutes.findIndex((item) => item.key === 'my-books');

  const booksNavigationUpdated = navigationRoutes[indexMyBooks].children.filter((child) => {
    if (!isDigitalCustomer.isDigital) {
      return child.key !== 'Digital-books';
    }
    return true;
  });

  const navigationRoutesUpdated = navigationRoutes.map((item) => {
    if (item.key === 'my-books') {
      return {
        ...item,
        children: booksNavigationUpdated,
      };
    }
    return item;
  });

  return (
    <div
      className={cn('pl-3 py-4 bg-white border-divider text-sm', {
        'fixed top-0 left-1 z-50 h-fit w-[calc(100%-8px)] border-[1px] shadow-box rounded-lg': isMobile,
        'h-full pb-20 overflow-y-auto border-r-[1px]': !isMobile,
      })}
    >
      <ul
        className={cn('font-medium space-y-2', {
          'mt-[96px]': (hasTrial || welcomeJourneyEligible) && isMobile,
          'mt-[44px]': !hasTrial && !welcomeJourneyEligible && isMobile,
        })}
      >
        {navigationRoutesUpdated.map((item) => (
          <>
            {shouldDisplayMenuItem(item) &&
              (() => {
                const isDisabled = welcomeJourneyEligible ? item.name !== 'Welcome journey' : false;

                return isDisabled ? (
                  <Tooltip
                    className={cn(
                      'ml-[100px] w-[200px]',
                      { 'ml-[70px]': item?.name === CAMPAIGNS },
                      { 'ml-[140px]': item?.name === LEADS },
                      { 'ml-[90px]': item?.name === TRAINING },
                    )}
                    content="Complete the action steps below to unlock this"
                    placement="right"
                  >
                    <li key={item.key} className="relative">
                      {!item.children ? (
                        <MenuItem
                          name={item?.name}
                          Icon={item?.icon}
                          href={item?.href}
                          ActionIcon={item?.actionIcon}
                          current={pathname === item?.href}
                          welcomeJourneyEligible={welcomeJourneyEligible}
                        />
                      ) : (
                        <DropdownMenu
                          item={item}
                          open={pathname.includes(item.key)}
                          options={undefined}
                          current={pathname === item?.href}
                          welcomeJourneyEligible={welcomeJourneyEligible}
                        />
                      )}
                    </li>
                  </Tooltip>
                ) : (
                  <li key={item.key} className="relative">
                    {!item.children ? (
                      <MenuItem
                        name={item?.name}
                        Icon={item?.icon}
                        href={item?.href}
                        ActionIcon={item?.actionIcon}
                        current={pathname === item?.href}
                        welcomeJourneyEligible={welcomeJourneyEligible}
                      />
                    ) : (
                      <DropdownMenu
                        item={item}
                        open={pathname.includes(item.key)}
                        options={undefined}
                        current={pathname === item?.href}
                        welcomeJourneyEligible={welcomeJourneyEligible}
                      />
                    )}
                  </li>
                );
              })()}
          </>
        ))}
      </ul>
    </div>
  );
};

export default SidebarMenu;

import { useContext } from 'react';

import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { CartIcon, PinMap } from 'modules/v2/common/components/SvgIcon';
import OrderTag from '../../../common/AtomicDesign/atoms/OrderTag';
import OrderedDateTime from '../../../common/AtomicDesign/atoms/OrderedDateTime';

import { GuidesContext } from '../context';
import { Tooltip } from '../../../common/AtomicDesign/molecules';

const OrderedCard = ({ orderData, onBuyAgain }) => {
  const ADDRESS_MAX_LENGTH = 70;

  const { setGuideQuantity, setSelectedOrderContext } = useContext(GuidesContext);

  const handleNavigateToCheckout = (orderId) => {
    setSelectedOrderContext(orderData);
    setGuideQuantity(orderData.qty);
    onBuyAgain(orderId);
  };

  const GuideThumbnail = ({ src, alt, className }) => (
    <div
      className={`h-32 md:h-48 overflow-hidden rounded-lg relative w-auto transition-transform duration-200 ${className}`}
    >
      <img
        src={src}
        alt={alt}
        className="h-full max-w-none object-cover rounded-lg transition-transform duration-200"
      />
    </div>
  );

  const QuantityTag = ({ children }) => (
    <div className="flex font-semibold items-center gap-2 rounded-md px-2 py-1 text-neutral-900 text-sm bg-neutral-100">
      {children}
    </div>
  );

  function shippingAddressText() {
    const {
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state = '',
      country = '',
      pincode = '',
    } = orderData?.shippingAddress || {};

    const address = [addressLine1, addressLine2, city, state, country, pincode]
      .filter(Boolean)
      .join(', ');

    return {
      fullAddress: address,
      displayAddress:
        address.length > ADDRESS_MAX_LENGTH
          ? `${address.slice(0, ADDRESS_MAX_LENGTH)}...`
          : address,
    };
  }

  const { fullAddress, displayAddress } = shippingAddressText();

  return (
    <>
      <Box className="mb-6 relative">
        <div className="text-base gap-2 flex-row items-center px-6 py-[22px] text-boxTitle">
          <OrderedDateTime value={orderData?.createdAt} />
          <span className="md:ml-0 sm:ml-0 flex-row text-base">
            <PinMap width={24} height={24} />
            <span className="font-semibold mr-1">Shipped to</span>
            <Tooltip
              content={fullAddress}
              trigger="hover"
              placement="top"
              theme={{
                base: 'text-neutral-50 text-sm p-2 pr-3 pl-3 rounded-lg',
              }}
            >
              <span>{displayAddress}</span>
            </Tooltip>
          </span>
        </div>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 border-t-[1px] border-t-neutral-200 overflow-visible">
          <div className="p-6 flex gap-4">
            <div className="w-fit">
              <GuideThumbnail
                src={orderData?.thumbnail?.url}
                alt={orderData.title}
                className="group-hover:scale-105"
              />
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex flex-col mb-2">
                <OrderTag className="mb-2">Order# {orderData?.orderId}</OrderTag>
                <div className="flex flex-row items-center mr-2">
                  <span className="mr-2 lowercase first-letter:capitalize">{orderData?.title}</span>{' '}
                  <QuantityTag>Qty: {orderData.qty}</QuantityTag>
                </div>
              </div>

              <Button
                type="dark"
                onClick={() => handleNavigateToCheckout(orderData?.orderId)}
                onKeyDown={handleNavigateToCheckout}
              >
                <CartIcon width={24} height={22} /> Buy again
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default OrderedCard;

export const ChainIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_23672_16521)">
      <path d="M6.6976 9.33301C5.69363 8.32513 5.78406 6.60026 6.89959 5.48039L10.1314 2.23609C11.2469 1.11623 12.9651 1.02544 13.9691 2.03332C14.973 3.04119 14.8826 4.76605 13.7671 5.88591L12.1512 7.50807" stroke="#252525" strokeWidth="1.5" strokeLinecap="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.77316 6.13564C9.06662 5.84331 9.5415 5.84423 9.83382 6.13769C11.1801 7.4892 10.9854 9.69009 9.63183 11.0489L8.01596 12.671L6.40009 14.2932L6.40009 14.2932C5.04558 15.653 2.84813 15.8497 1.49965 14.496C0.153362 13.1445 0.348104 10.9435 1.70164 9.58475L3.31753 7.96259C3.60986 7.66913 4.08473 7.66821 4.37819 7.96053C4.67165 8.25286 4.67257 8.72773 4.38025 9.02119L2.76435 10.6434C1.88681 11.5243 1.90069 12.7731 2.56236 13.4374C3.22184 14.0994 4.46081 14.1146 5.33737 13.2346C5.33737 13.2346 5.33737 13.2346 5.33737 13.2346L6.95324 11.6124L8.56912 9.99028L8.56912 9.99027C9.44666 9.10933 9.43278 7.86053 8.77111 7.19629C8.47878 6.90283 8.4797 6.42796 8.77316 6.13564Z" fill="#252525"/>
    </g>
    <defs>
      <clipPath id="clip0_23672_16521">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

const mockData = [
  {
    id: 1,
    name: 'seller',
    title: 'A Guide to Selling Your Home',
    guideColor: ['red', 'gold', 'white', 'purple', 'blue'],
    guideData: [
      {
        id: 1,
        color: 'red',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/red_e4f67ca3e5.jpg',
        orderStatus: 'new',
        orderId: null,
      },
      {
        id: 2,
        color: 'gold',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/gold_ccb16d06ea.jpg',
        orderStatus: 'ordered',
        orderId: '0001',
      },
      {
        id: 3,
        color: 'white',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/white_d7b0b4c52d.jpg',
        orderStatus: 'finalize',
        orderId: '0002',
      },
      {
        id: 4,
        color: 'purple',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/purple_26d3cd729c.jpg',
        orderStatus: 'new',
        orderId: null,
      },
      {
        id: 5,
        color: 'blue',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/blue_ccd6ff0bf1.jpg',
        orderStatus: 'new',
        orderId: null,
      }
    ]
  }
];

export default mockData;